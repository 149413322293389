import 'babel-polyfill';
import 'core-js/modules/es6.symbol';
import React from "react";
import { Provider } from "mobx-react";
import AppStore from "store/AppStore";
import ReaderStore from "store/ReaderStore";
import axios from "axios";
import i18next from "lib/i18n";
import CacheBuster from "lib/CacheBuster";

const __GATSBY_MOBX_STORE__ = '__GATSBY_MOBX_STORE__';

const readerStore = new ReaderStore();
const appStore = new AppStore();

if (!window[__GATSBY_MOBX_STORE__]) {
  window[__GATSBY_MOBX_STORE__] = { readerStore, appStore };
}

axios.defaults.headers.common = {
  "Cache-Control": "no-cache",
  "Pragma": "no-cache",
  "Expires": "0"
};

/*const wrapWithProvider = ({ element }) => (
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        // You can decide how and when you want to force reload
        refreshCacheAndReload();
      }
      return (
        <Provider readerStore={readerStore} appStore={appStore}>
          {element}
        </Provider>
      );
    }}
  </CacheBuster>
);*/
const wrapWithProvider = ({ element }) => (
  <Provider readerStore={readerStore} appStore={appStore}>
    {element}
  </Provider>
);

export const wrapRootElement = wrapWithProvider;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const currentPosition = getSavedScrollPosition(location)
  const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

  //window.scrollTo(...(currentPosition || [0, 0]))
  
  //console.log("currentPosition",currentPosition)
  
  return false
}

export const onPreRouteUpdate = async () => {
  await readerStore.syncSessionCookie();
}

export const onClientEntry = async () => {
  await axios.get("/api/jumperrwdWs/session").then(async (res) => {
    let session = res.data.session;
    if (session !== undefined) {
      if (session.reload === "true") {
        window.location.reload();
        return false;
      } else {
        if (session.loginType !== undefined) {
          readerStore.setAuth(session.loginType);
        } else {
          readerStore.setAuth(false);
        }
        if (!session.csrfToken || session.csrfToken === "") {
          session.csrfToken = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
            c
          ) {
            var r = (Math.random() * 16) | 0,
              v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          });
          await axios.get("/api/jumperrwdWs/setSession?key=csrfToken&value=" + session.csrfToken)
            .then((res) => {});
        }
        readerStore.setSession(session);
        let locales = session.locales || "zh";
        if (locales !== i18next.language) {
          i18next.changeLanguage(locales);
        }
      }
    } else {
      readerStore.setAuth(false);
    }
  });
  let configList = await axios
    .post("/api/jumperrwdWs/getConfigList", {
      argNameList: [
        "hyint.module.isIndexGuestLogin",
        "jumper.common.guest.campus",
        "jumper.common.guest.offcampus",
        "jumper.common.ispjournals",
        "jumper.common.iscatalog",
        "jumper.common.isdiscovery",
        "jumper.common.ishyint",
        "jumper.common.isresource",
        "jumper.common.resourcetabmode",
        "jumper.common.tabbar.default",
        "jumper.common.eds.issearchboxapi",
        "jumper.common.eds.issearchboxapi.isproxy",
        "jumper.common.eds.custid",
        "jumperrwd.layout.cssStyle",
        "jumperrwd.login.libraryHome", 
        "jumper.common.isMyCollection",
        "jumper.common.search.isSdi",
        "jumper.common.toplogo",
        "jumper.common.toplogo.linkmod",
        "hyint.esource.libraryHomeUrl",
        "jumper.common.logintimeout",
        "jumper.common.logintimeout2",
        "jumper.common.logintimeout.directlogout",
        "jumperrwd.common.base_href",
        "jumperrwd.common.isEbooks",
        "jumperrwd.login.userTypes",
        "jumperrwd.login.logoutToHome",
        "jumperrwd.header.topLogoNewWindow",
        "jumperrwd.integration.nowChooseHidden",
        "jumper.common.eds.defaultFulltextLimiter",
        "jumper.common.eds.fulltextLimiterItems",
        "jumperrwd.login.userInputType",
        "jumperrwd.login.notHiddenInputUserType",
        "jumperrwd.loginBind.alertCannotSkipUserType",
        "hyint.core.gtmAccount",
        "fcu.clientid",
        "jumperrwd.login.alertHidden",
        "jumperrwd.common.webAppName",
        "jumperrwd.login.guestCheck",
      ],
    })
    .then((res) => {
      return res.data;
    });
  appStore.setGlobalConfig(configList);
  let gtmAccount = configList["hyint.core.gtmAccount"] || "";
  if (gtmAccount !== "") {
    let body = document.body;
    let gtmNoScirptElement = document.createElement("noscript");
    let gtmIframeElement = document.createElement("iframe");
    gtmIframeElement.src = "https://www.googletagmanager.com/ns.html?id=" + gtmAccount;
    gtmIframeElement.height = "0";
    gtmIframeElement.width = "0";
    gtmIframeElement.title = "gtmIframe";
    gtmIframeElement.style.display = "none";
    gtmIframeElement.style.visibility = "hidden";
    gtmIframeElement.setAttribute("aria-hidden", "true");
    gtmNoScirptElement.appendChild(gtmIframeElement);
    body.insertBefore(gtmNoScirptElement, body.firstChild);
  }
}
